import { Injectable } from '@angular/core';
import { NbComponentStatus, NbGlobalPhysicalPosition, NbToastrService } from '@nebular/theme';
import { caja } from '../models/caja';
import { DateTime } from "luxon";
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  usuario:any={};
  coneccionImpresora:string;
  impresoraSeleccionada:any={};
  incluirInventario:boolean=true;
  stockMinimo:number=2;
  facturacion:boolean=true;
  domain: String = `${environment.hostBackend}:${environment.puertoBackendHttps}${environment.pathBackend}`; //dominio o host del servidor node.js
  constructor(
    private toastrService: NbToastrService,
    public http:HttpClient
    ) {
    this.getIncluirInventario();
   }

  setUsuario(data){
    this.usuario=data;
  }

  getUsuario(){
    return this.usuario;
  }

  setImpresoraSeleccionada(impresora:string){
    this.impresoraSeleccionada={nombre:impresora}
     }

  getImpresoraSeleccionada(){
    let conf = JSON.parse(localStorage.getItem("CONF"));
    if(conf != null){
      this.impresoraSeleccionada = conf.impresora?.nombre;
      return this.impresoraSeleccionada;
    }
    return undefined;
  }

  getFacturacion():Promise<boolean>{
    return new Promise((resolve,reject)=>{
    let conf = JSON.parse(localStorage.getItem("CONF"));
    if(conf != null){
      let temp = conf.facturacion?.activo ?? undefined;
      if(temp=="true" || temp==undefined){
        this.facturacion=true;
      }else{
        this.facturacion=false;
      }
    }
    resolve(this.facturacion);
  });
  }

  getIncluirInventario():Promise<boolean>{
    return new Promise((resolve,reject)=>{
    let conf = JSON.parse(localStorage.getItem("CONF"));
    if(conf != null){
      let temp = conf.inventario?.incluirInventario ?? undefined;
      if(temp=="true" || temp==undefined){
        this.incluirInventario=true;
      }else{
        this.incluirInventario=false;
      }
    }
    resolve(this.incluirInventario);
  });
  }

  getTipodeComunicacionconImpresora():Promise<string>{
    return new Promise((resolve,reject)=>{
    let conf = JSON.parse(localStorage.getItem("CONF"));
    if(conf != null){
      let temp = conf.impresora?.conexion ?? undefined;
      if(temp==undefined){
        this.coneccionImpresora ="USB";
      }else{
        this.coneccionImpresora=temp;
      }
    }else{
      this.coneccionImpresora ="USB";
    }
    resolve(this.coneccionImpresora);
  });
  }
  

  setIncluirInventario(incluir:boolean){
    this.incluirInventario=incluir;
  }

  setFacturacion(incluir:boolean){
    this.facturacion=incluir;
  }


  setStockMinimo(stockMinimo){
    this.stockMinimo=stockMinimo;
    let data = JSON.parse(localStorage.getItem("CONF"));
    if(data != null){
    data.inventario={stockMinimo:stockMinimo};
    }else{
      data = {inventario:{stockMinimo:stockMinimo}};
    }
    localStorage.setItem("CONF", JSON.stringify(data));
    // this.showToast('info','Informacion',`Stock minimo actualizado!`);
  }

  getStockMinimo():Promise<number>{
    return new Promise((resolve,reject)=>{
    let conf = JSON.parse(localStorage.getItem("CONF"));
    if(conf != null){
      let temp = conf.inventario?.stockMinimo ?? undefined;
      if(temp==undefined){
        this.stockMinimo=2;
      }else{
        this.stockMinimo=Number(temp);
      }
    }
    resolve(this.stockMinimo);
  });
  }

/**
 * Obtiene un objeto de tipo caja del localstorage de la configuracion del usuario al aperturar la caja
 * @returns Una promesa que resuelve un objeto de tipo caja cuando obtenemos de forma existosa la caja del localstorage
 * 
 */
  getCaja():Promise<caja>{
    // se retorna una promesa
    return new Promise((resolve,reject)=>{
    //obtenemos la configuracion del localstorage
    let conf = JSON.parse(localStorage.getItem("CONF"));
    //verificamos si existe el objeto CONF en el localStorage
    if(conf != null){
      //verificamos si existe la propiedad caja en el objeto conf si no existe entonces lo definimos como undefined
      let temp = conf.caja ?? undefined;
      // si caja no esta definido entonces creamos esa propiedad
      if(temp==undefined){
        //creamos un objeto de tipo caja y lo inicializamos 
        let caja={
          monto_inicio:0,
          agregados:[],
          retiros:[],
          monto_final:0
        }
        // se le agrega a el objeto conf una nueva propiedad llamada caja con el valor igual al objeto caja
        conf.caja=caja;
        // reemplazamos el objeto conf con el nuevo objeto conf
        localStorage.setItem("CONF", JSON.stringify(conf));
        // se resulve la promesa con el objeto caja de tipo caja
        return resolve(conf.caja);
      }else{
        //si caja ya esta definido resolvemos caja
        return resolve(conf.caja);
      }
    }else{
      //creamos el objeto caja
      let caja:caja={
        fecha:DateTime.now().toString(),
        monto_inicio:0,
        agregados:[],
        retiros:[],
        monto_final:0
      }
      //creamos el objeto configuracion y como propiedad caja y como valor el objeto caja
      let CONF={
        caja:caja
      }
      // reemplazamos el objeto conf con el nuevo objeto conf
      localStorage.setItem("CONF", JSON.stringify(CONF));
       // se resulve la promesa con el objeto caja de tipo caja
      return resolve(caja);
    }
  });
  }

  /**
   * Establece en el localStorage en el objeto caja la propiedad
   * de inicio de caja
   * @param monto_inicial es la cantidad con que la caja va a iniciar
   * @returns Promesa que resuelve de tipo string y si es rechazada devuelve un error de tipo string
   */
  setMontoInicial(monto_inicial):Promise<string>{
    return new Promise((resolve,reject)=>{
    //obtenemos la configuracion del localstorage
    let conf = JSON.parse(localStorage.getItem("CONF"));
    //verificamos si existe el objeto CONF en el localStorage
    if(conf != null){
      // si la propiedad caja no esta definida en el objeto conf entonces le 
      // asignamos undefined
      let temp = conf.caja ?? undefined;
      // si caja no esta definida rechazamos la promesa
      if(temp==undefined){
        return reject(`caja no esta definida`);
      }else{
        conf.caja.monto_inicio=monto_inicial.valor;
        // el monto final es igual al monto inicial al momento de la
        // apertura de la caja
        conf.caja.monto_final=monto_inicial.valor;
        // se reemplaza en el localstorage la configuracion
        localStorage.setItem("CONF", JSON.stringify(conf));
        // resolvemos la promesa
        return resolve(`Se definió valor inicial por ${monto_inicial.valor}`);
      }
    }else{
      // rechazamos la promesa
      return reject(`Configuracion no definida`);
    }
  });
  }

  /**
   * Agrega a un Arreglo del objeto caja el nuevo monto agregado a caja
   * @param monto_agregado Es el valor en quetzales que se suma a la caja despues de la apertura
   * @returns Una Promesa que resuelve un string cuando se agrega el monto agregado o la rechaza con un error de tipo string
   */
  setMontoAgregado(monto_agregado):Promise<string>{
    return new Promise((resolve,reject)=>{
      console.log(monto_agregado);
    //obtenemos la configuracion del localstorage
    let conf = JSON.parse(localStorage.getItem("CONF"));
    //verificamos si existe el objeto CONF en el localStorage
    if(conf != null){
      let temp = conf.caja ?? undefined;
      // si caja no esta definida rechazamos la promesa
      if(temp==undefined){
        return reject(`caja no esta definida`);
      }else{
        //creamos un objeto de tipo date con la libreria de tercero luxon
        let local= DateTime.now();
        // definimos la zona para la fecha
        local.setZone('utc');
        // se agrega a el arelgo agregados del objeto caja 
        // el monto agregado
        conf.caja.agregados.push({valor:monto_agregado.valor,hora:local.toFormat("HH:mm:ss")});
        //definimos variables que contedran subtotales
        let total_agregados=0;
        let total_retiros=0;
        let total=0;
        // se recorre el arreglo de agregados y se suma
        for (const agregado of conf.caja.agregados) {
          total_agregados+=agregado.valor;
        }
        // se recorre el arreglo de retiros y se suma
        for (const retiro of conf.caja.retiros) {
          total_retiros+=retiro.valor;
        }
        // el total de monto final es la suma de agregados mas la suma del monto inicial menos el total de retiros 
        total=(conf.caja.monto_inicio+total_agregados)-total_retiros;
        // la suma de los agregados mas el monto inicial es igual al monto  final
        conf.caja.monto_final=total;
        // se reemplaza en el localstorage la configuracion
        localStorage.setItem("CONF", JSON.stringify(conf));
        // resolvemos la promesa
        return resolve(`se agrego ${monto_agregado.valor} a caja`);
      }
    }else{
      return reject(`Configuracion no definida`);
    }
  });
  }


  /**
   * Agrega a un Arreglo del objeto caja el nuevo monto agregado a caja
   * @param salida Es el valor en quetzales que se suma a la caja despues de la apertura
   * @returns Una Promesa que resuelve un string cuando se agrega el monto agregado o la rechaza con un error de tipo string
   */
   setSalidaEfectivo(salida):Promise<string>{
    return new Promise((resolve,reject)=>{
    //obtenemos la configuracion del localstorage
    let conf = JSON.parse(localStorage.getItem("CONF"));
    //verificamos si existe el objeto CONF en el localStorage
    if(conf != null){
      let temp = conf.caja ?? undefined;
      // si caja no esta definida rechazamos la promesa
      if(temp==undefined){
        return reject(`caja no esta definida`);
      }else{
        //creamos un objeto de tipo date con la libreria de tercero luxon
        let local= DateTime.now();
        // definimos la zona para la fecha
        local.setZone('utc');
        // se agrega a el arelgo agregados del objeto caja el monto agregado
        conf.caja.retiros.push({
          'motivo':salida.motivo,
          'valor':salida.valor,
          'hora':local.toFormat("HH:mm:ss"),
          'nombre':salida.nombre});
        let total_agregados=0;
        let total_retiros=0;
        let total=0;
        // se recorre el arreglo de agregados y se suma
        for (const agregado of conf.caja.agregados) {
          total_agregados+=agregado.valor;
        }
        // se recorre el arreglo de retiros y se suma
        for (const retiro of conf.caja.retiros) {
          total_retiros+=retiro.valor;
        }
        console.log(`${total_agregados} ${total_retiros} ${total}`);
        // el total de monto final es la suma de agregados mas la suma del monto inicial menos el total de retiros 
        total=(conf.caja.monto_inicio+total_agregados)-total_retiros;
        
        if(total<0){
          return reject(`la solicitud de retiro por ${salida.valor} es mayor a lo que posee la caja`);
        }
        // la suma de los agregados mas el monto inicial es igual al monto  final
        conf.caja.monto_final=total;
        // se reemplaza en el localstorage la configuracion
        localStorage.setItem("CONF", JSON.stringify(conf));
        // resolvemos la promesa
        return resolve(`se le dio salida de caja por un monto de ${salida.valor}`);
      }
    }else{
      return reject(`Configuracion no definida`);
    }
  });
  }

  /**
   * Verifica si ya existe valor en valor inicial de la caja
   * verifica si ya se aperturo la caja
   * @returns 
   */
  existeMontoinicial():Promise<string>{
    return new Promise((resolve,reject)=>{
    //obtenemos la configuracion del localstorage
    let conf = JSON.parse(localStorage.getItem("CONF"));
    //verificamos si existe el objeto CONF en el localStorage
    if(conf != null){
      let temp = conf.caja ?? undefined;
      if(temp==undefined){
        return reject(`caja no esta definida`);
      }else{
        let definido=conf.caja.monto_inicio ?? undefined;
        if(definido==undefined || definido==0){
          return reject(`Monto inicial aun no definido`);
        }
        return resolve(`Monto inicial ya definido `);
      }
    }else{
      return reject(`Configuracion no definida`);
    }
  });
  }

    /**
   * Guarda en base de datos los valoes de caja
   * @returns Una Promesa que resuelve un string cuando se guarda exitosamente en la base de datos
   */
     cerrarCaja():Promise<string>{
      return new Promise((resolve,reject)=>{
      //obtenemos la configuracion del localstorage
      let conf = JSON.parse(localStorage.getItem("CONF"));
      //verificamos si existe el objeto CONF en el localStorage
      if(conf != null){
        let temp = conf.caja ?? undefined;
        // si caja no esta definida rechazamos la promesa
        if(temp==undefined){
          return reject(`caja no esta definida`);
        }else{
          try {
            
            let guardar=this.guardarCaja$({'caja':conf.caja,'user_id':this.getUsuario()[`${environment.namespace}/user_id`]}).toPromise();
            let caja:caja={
              fecha:DateTime.now().toString(),
              monto_inicio:0,
              agregados:[],
              retiros:[],
              monto_final:0
            }
            // agregamos el objeto caja a la propiedad caja de configuracion
            conf.caja=caja;
            // se reemplaza en el localstorage la configuracion
            localStorage.setItem("CONF", JSON.stringify(conf));
            // resolvemos la promesa
            return resolve(`Se cerro caja correctamente`);
          } catch (error) {
            console.error(error);
            return reject(`Ocurrio en error al guardar caja ${error}`);
          }
        }
      }else{
        return reject(`Configuracion no definida`);
      }
    });
    }
  

    public guardarCaja$(data) {
      return this.http.post(`${this.domain}/caja/nuevocaja`,data);
    }

  public showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: true,
      duration: 5000,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.TOP_RIGHT,
      preventDuplicates: false,
    };
  
    this.toastrService.show(
      body,
      `${title}`,
      config);
  }
}
