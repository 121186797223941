import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';
import  {tokenFEL} from '../facturacion/moduloFEL/tokenFEL';

@Injectable()
export class httpInterceptor implements HttpInterceptor {

    constructor(public auth0Service: AuthService){

    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        
        // obtenemos el token de Auth0 Custom API guardado en localStorage
        // const token_Auth0_Custom_API = localStorage.getItem('auth0T');

        const token_Auth0_Management_API= localStorage.getItem('auth0_T_M_API');

        // si en la solicitud hay una cabecera que diga que la peticion es hacia
        // Auth0 Management API y requiere otro token
        if(req.headers.get("MAPI"))
        {
            console.log("MAPI");
            // loggerBluter.info('MAPI');
            // ATTENTION si no se elimina el header MAPI el Mangement API de Auth0 falla 
            // y no devuelve el codigo de eror correspondiente porque la cabecera MAPI no la reconoce
            // la cabecera MAPI es generada por la aplicacion bluter y no pertenece a http
            // clona de nuevo y se elimina el header personalizado MAPI
            const request = req.clone({
                headers:req.headers.delete('MAPI')
            });
            // se examina si existe un token Management API
            if (!token_Auth0_Management_API) {

                console.log("No existe token MAPI");
                // NOTE
                // enviamos la peticion ya sin el header custom MAPI para que auth0
                // nos devuelva el estado 401 con el error de que falta el token
                // entonces nuestra aplicacion pide el token y vuelve a enviar la peticion
                return next.handle(request);
            }

            // clona el objeto req y reestablece los headers y adjunta el token de Auth0 Management API
            const request2 = req.clone({
                headers: request.headers.set('Authorization', `Bearer ${token_Auth0_Management_API}`)
            });

            // se llama a objeto next y a su mentodo handle y se le pasa el objeto request modificado
            // y se envia
            return next.handle(request2);
        }
        else if(req.headers.get('FEL'))
        {   
            // recuperamos el token FEL guardado en el localStorage
            var token = tokenFEL.retornarToken();
            // eliminamos el header temporal FEL
            let headers_temporales:HttpHeaders= req.headers.delete('FEL');
            // a los headers temporales le anadimos el header Authorization con el token de tipo bearer
            let headers_finales:HttpHeaders=headers_temporales.set('Authorization', `Bearer ${token}`);
            // esta opcion es solo para fines de prueba para generar el error 401 Inautorized
            // let headers_finales=headers_temporales;
            //clonamos el objeto request y le pasamos los headers finales de la peticion
            const request = req.clone({
                headers:headers_finales
            });
            // el metodo handle de next ejecuta la peticion y le pasamos el objeto request
            return next.handle(request);
        }

        // si en la solicitud hay una cabecera que anula adjuntar el token
        // por alguna situacion
        else if(req.headers.get("skip"))
        {
            // no hacer nada y seguir con la solicitud
            return next.handle(req);
        }
        // como se necesita llamar a una funcion asyncrona con asyn y await en la funcion
        // intercept no se puede entonces usamos otra funcion que si pueda pero usamos la
        // funcion from de rxjs para convertir una promesa a un observable.
        return from(this.handle(req, next));
    }

    async handle(req: HttpRequest<any>, next: HttpHandler) {
        let token_Auth0_Custom_API= await this.auth0Service.getAccessTokenSilently().toPromise();
        console.log("CAPI");
        // clona el objeto req y reestablece los headers y adjunta el token
        const headers = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${token_Auth0_Custom_API}`)
        });

        // necesario llamar a toPromise() porque from de rxjs espera una promesa para convertirla
        // a un observable
        return next.handle(headers).toPromise();

    }
}