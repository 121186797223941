'use strict';

// T-M-FEL   = Token FEL
function verificarToken(){
    var token=localStorage.getItem("T-M-FEL");
    if(!token){
        return false;
    }
    return true;
}

function verificarFechaToken(){
    var fechatoken=localStorage.getItem("T-V-FEL");
    if(!fechatoken){
        return false;
    }
    return true;
}

function guardarToken(TOKEN,FECHA_VENCIMIENTO){
    localStorage.setItem("T-M-FEL",TOKEN);
    localStorage.setItem("T-V-FEL",FECHA_VENCIMIENTO);
}

function removerTokenVencido(){
    localStorage.removeItem("T-M-FEL");
}

function retornarToken(){
    return localStorage.getItem("T-M-FEL");
}

function verificarVigenciaToken(){
    const fecha= new Date();
    var hoy=`${fecha.getFullYear()}/${fecha.getMonth()}/${fecha.getDate()}`;
    console.log(`${fecha.getFullYear()}/${fecha.getMonth()}/${fecha.getDate()}`);
    var fechaVen:any=localStorage.getItem("T-V-FEL");
    var fechaVencimiento=fechaVen.substr(0,10);
    console.log(fechaVen);
    console.log(fechaVencimiento);

    var shoy=new Date(hoy);
    var sven=new Date(fechaVencimiento);
    if(shoy.getTime()<sven.getTime()){
        return true;
    }else{
        return false;
    }
}

var tokenFEL={
    verificarToken,
    verificarFechaToken,
    guardarToken,
    removerTokenVencido,
    retornarToken,
    verificarVigenciaToken
}

export {tokenFEL}